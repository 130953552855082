// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const tabs = {
  // InterestIncome: { type: 'Income', uri: '/CRV', formType: 'CRV', listType: 'CRV', voucherType: "II" },
  CustomerAdvance: { type: 'Customer Advance', uri: '/Recoveries', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "CA" },
  CustomerPayment: { type: 'Customer Receiving', uri: '/Recoveries', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "CP" },
  // RetainerPayment: { type: 'Retainer Payment', uri: '/CRV', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "RP" },
  TransferFromAccount: { type: 'Transfer From Another Account', uri: '/Recoveries', formType: 'Recoveries', listType: 'Recoveries', voucherType: "TFA" },
  OtherIncome: { type: 'Other Income', uri: '/CRV', formType: 'CRV', listType: 'DC NOTE', voucherType: "OI" },
  ExpenseRefund: { type: 'Expense Refund', uri: '/CRV', formType: 'CRV', listType: 'CRV', voucherType: "ER" },
  DepositFromAccounts: { type: 'Deposit From Other Accounts', uri: '/Recoveries', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "DOA" },
  OwnerContribution: { type: 'Owner\'s Contribution', uri: '/CRV', formType: 'CRV', listType: 'CRV', voucherType: "OC" },
  VendorCreditRefund: { type: 'Vendor Credit Refund', uri: '/Recoveries', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "VCR" },
  VendorPaymentRefund: { type: 'Vendor Payment Refund', uri: '/Recoveries', formType: 'Recoveries', listType: 'DC NOTE', voucherType: "VPR" },
  // Expense: { type: 'Exepnse', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "EXP" },
  VendorAdvance: { type: 'Vendor Advance', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VA" },
  VendorPayment: { type: 'Vendor Payment', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VP" },
  TransferToAccount: { type: 'Transfer To Another Account', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "TTA" },
  CardPayment: { type: 'Card Payment', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "CRP" },
  OwnerDrawings: { type: 'Owner Drawings', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "OD" },
  // ExpenseRefund: { type: 'Expense Refund', uri: '/CPV', formType: 'CPV' },
  DepositToAccounts: { type: 'Deposit To Other Accounts', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "DTA" },
  CreditNoteRefund: { type: 'Credit Note Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "CNR" },
  PaymentRefund: { type: 'Payment Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "PR" },
  // VendorCreditRefund: { type: 'Vendor Credit Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VCR" },
  EmployeeReimbursement: { type: 'Employee Advance', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "ER" }
}

export const setLoading = createAsyncThunk('appPayments/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appPayments/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))

  let response;
  if(params.withBalance) {
    response = await axios.get('/Defaults/DetailCashBankCOAListWithBalances', {params})
  } else{
    response = await axios.get('/Defaults/DetailCashBankCOAList', {params})
  }
  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: response.data
  }
})

export const getTransactions = createAsyncThunk('appReceivings/getTransactions', async (params, { dispatch }) => {
  dispatch(setLoading({ getTransactions: true }))
  let response;
  const formType = tabs[params.tab].formType;

  if(!(params.avoidVoucher)) {
    params.voucherType = tabs[params.tab].voucherType;
  }
  delete params.tab
   if (formType === "CRV") {
    response = await axios.get(`/CRV/List`, { params })
  }else if (formType === "CPV"){
    response = await axios.get(`/CPV/List`, { params })
  } else if (formType === "Payments"){
    response = await axios.get(`/Payments/List`, { params })
  } else {
     response = await axios.get(`/Recoveries/List`, { params })

  }
  dispatch(setLoading({ getTransactions: false }))
  return {
     data: response.data,
     params
   }

})

export const clearTransactions = createAsyncThunk('appReceivings/clearTransactions', async () => {
  return []
})

export const getVoucher = createAsyncThunk('appReceivings/getVoucher', async (recno, { dispatch }) => {
  dispatch(setLoading({ getVoucher: true }))
  const response = await axios.get(`/CPV/${recno}`)
  dispatch(setLoading({ getVoucher: false }))

  return response.data
})

export const getTransactionAccount = createAsyncThunk('appReceivings/getTransactionAccount', async (recno, { dispatch }) => {
  dispatch(setLoading({ getTransactionAccount: true }))
  dispatch(clearTransactions())
  const response = await axios.get(`/Defaults/DetailCashBankCOAList?Recno=${recno}`)
  dispatch(setLoading({ getTransactionAccount: false }))
  return response.data
})

export const appReceivings = createSlice({
  name: 'appReceivings',
  initialState: {
    voucher: {},
    data: [],
    transactions: [],
    account: null,
    totalRecords: 1,
    params: {},
    trans_params: {},
    loadingState: { getData: false, getVoucher: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTransactionAccount.fulfilled, (state, action) => {
        state.account = action.payload.data.length ? action.payload.data[0] : null
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.trans_params = action.payload.params
      })
      .addCase(clearTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload
        state.totalRecords = 0
        state.params = {}
      })
      .addCase(getVoucher.fulfilled, (state, action) => {
        state.voucher = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appReceivings.reducer
