// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appPayrollPayments/setLoading', async (loadingState) => ({...loadingState}))

export const getData = createAsyncThunk('appPayrollPayments/getData', async (params, {dispatch, getState}) => {
    dispatch(setLoading({getData: true}))
    let response;

    if(params?.type === 0) {
        response = await axios.get('/PayPeriodPayRunPayments/GetPayRollPayRunDues', {params})
    } else {
        response = await axios.get('/PayPeriodPayRunPayments/GetPayRollPayRunPaymentHistory', {params})
    }

    dispatch(setLoading({getData: false}))
    return {
        params,
        data: response?.data
    }
})

export const appPayrollPaymentsSlice = createSlice({
    name: 'appPayrollPayments',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: {getData: false}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState =  {...state.loadingState, ...action.payload}
            })
    }
})

export default appPayrollPaymentsSlice.reducer
