// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const tabs = {
  Expense: { type: 'Exepnse', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "EXP" },
  VendorAdvance: { type: 'Vendor Advance', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VA" },
  VendorPayment: { type: 'Vendor Payment', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VP" },
  TransferToAccount: { type: 'Transfer To Another Account', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "TTA" },
  CardPayment: { type: 'Card Payment', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "CP" },
  OwnerDrawings: { type: 'Owner Drawings', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "OD" },
  // ExpenseRefund: { type: 'Expense Refund', uri: '/CPV', formType: 'CPV' },
  DepositToAccounts: { type: 'Deposit To Other Accounts', uri: '/CPV', formType: 'CPV', listType: 'CPV', voucherType: "DTA" },
  CreditNoteRefund: { type: 'Credit Note Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "CNR" },
  PaymentRefund: { type: 'Payment Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "PR" },
  // VendorCreditRefund: { type: 'Vendor Credit Refund', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "VCR" },
  EmployeeReimbursement: { type: 'Employee Advance', uri: '/Payments', formType: 'Payments', listType: 'DCNOTE(Payment)', voucherType: "ER" }
}

export const setLoading = createAsyncThunk('appPayments/setLoading', async (loadingState) => ({ ...loadingState }))
export const getData = createAsyncThunk('appPayments/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/Defaults/DetailCashBankCOAList', { params })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: response.data
  }
})
export const getTransactions = createAsyncThunk('appPayments/getTransactions', async (params, { dispatch }) => {
  dispatch(setLoading({ getTransactions: true }))
  let response;
  const formType = tabs[params.tab].formType
  if(!(params.avoidVoucher)) {
    params.voucherType = tabs[params.tab].voucherType
  }
  if (formType === "CPV") {
    response = await axios.get(`/CPV/List`, { params })
  }
  else {
    response = await axios.get(`/Payments/List`, { params })
  }
  dispatch(setLoading({ getTransactions: false }))
  return response.data

})

export const clearTransactions = createAsyncThunk('appPayments/clearTransactions', async () => {
  return []
})

export const getVoucher = createAsyncThunk('appPayments/getVoucher', async (recno, { dispatch }) => {
  dispatch(setLoading({ getVoucher: true }))
  const response = await axios.get(`/CPV/${recno}`)
  dispatch(setLoading({ getVoucher: false }))
  return response.data
})

export const getTransactionAccount = createAsyncThunk('appPayments/getTransactionAccount', async (recno, { dispatch }) => {
  dispatch(setLoading({ getTransactionAccount: true }))
  dispatch(clearTransactions())
  const response = await axios.get(`/Defaults/DetailCashBankCOAList?Recno=${recno}`)
  dispatch(setLoading({ getTransactionAccount: false }))

  return response.data
})

export const appPayments = createSlice({
  name: 'appPayments',
  initialState: {
    voucher: {},
    data: [],
    transactions: [],
    account: null,
    totalRecords: 1,
    params: {},
    loadingState: { getData: false, getVoucher: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTransactionAccount.fulfilled, (state, action) => {
        state.account = action.payload.data.length ? action.payload.data[0] : null
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data.list
        state.totalRecords = action.payload.totalRecords
        state.params = action.payload.params
      })
      .addCase(clearTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload
        state.totalRecords = 0
        state.params = {}
      })
      .addCase(getVoucher.fulfilled, (state, action) => {
        state.voucher = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appPayments.reducer
