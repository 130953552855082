// ** Axios Imports
import axios from 'axios'

export const API_LOGIN = '/Login'
export const API_FORGOT_PASSWORD = '/Login/forgotpassword'
export const API_FORGOT_PASSWORD_RESET = '/Login/forgotpasswordreset'
export const API_FORGOT_PASSWORD_RESET_SUBMIT = '/Login/forgotpasswordresetsubmit'
export const API_REGISTER = '/Signup'
export const API_LOGIN_LOCATIONS = '/Login/PostLocations'
export const API_LOGIN_TOKEN = '/Login/PostToken'

export const Post = async (uri, data) => {
    try {

        const response = await axios.post(uri, data)
        return response.data
    } catch (error) {
        let er = error
        if (error.response) {
            er = error.response.data
        }
        return Promise.reject(er.error || er.message || er.title || er.errors || er)
    }
}

export const Put = async (uri, data) => {
    try {
        const response = await axios.put(uri, data)
        return response.data
    } catch (error) {
        let er = error
        if (error.response) {
            er = error.response.data
        }
        return Promise.reject(er.error || er.message || er.title || er.errors || er)
    }
}

export const Get = async (uri, params) => {
    try {
        const response = await axios.get(uri, { params })
        return response.data
    } catch (error) {
        let er = error
        if (error.response) {
            er = error.response.data
        }
        return Promise.reject(er.error || er.message || er.title || er.errors || er)
    }
}

export const Delete = async (uri) => {
    try {
        const response = await axios.delete(uri)
        return response.data
    } catch (error) {
        let er = error
        if (error.response) {
            er = error.response.data
        }
        return Promise.reject(er.error || er.message || er.title || er.errors || er)
    }
}

