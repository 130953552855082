// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appPayAdvances/setLoading', async (loadingState) => ({...loadingState}))

export const getData = createAsyncThunk('appPayAdvances/getData', async (params, {dispatch, getState}) => {
    dispatch(setLoading({getData: true}))
    const response = await axios.get('/PayPeriodAdvances/List', {params})
    dispatch(setLoading({getData: false}))
    return {
        params,
        data: response?.data
    }
})

export const appPayAdvancesSlice = createSlice({
    name: 'appPayAdvances',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: {getData: false}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload?.data?.data?.list??[]
                state.totalRecords = action.payload?.data?.data?.totalRecords??0
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState =  {...state.loadingState, ...action.payload}
            })
    }
})

export default appPayAdvancesSlice.reducer
